<template>
  <div class="fc delivery-index">
    <div class="flex card-info">
      <img class="ava" :src="userInfo.avatar" alt="用户头像">
      {{ userInfo.nickname }}
    </div>
    <div class="f1 fc log-list">
      <van-tabs v-model="activeName" @change="getList(true)">
        <van-tab title="全部" :name="0" />
        <van-tab title="有害" :name="4" />
        <van-tab title="可回收" :name="2" />
        <van-tab title="易腐" :name="1" />
        <van-tab title="其他" :name="3" />
      </van-tabs>
      <van-button class="search" round type="info" size="small" @click="showTimePicker = true">搜索</van-button>
      <div class="f1 scroll">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getList">
          <van-cell v-for="(item, index) in list" :key="index" size="large" :title="formatTitle(item)" :label="$helper.formatTime(item.weightTime)" :value="item.weight + 'kg'" />
        </van-list>        
      </div>
    </div>
    <van-popup v-model="showTimePicker" position="bottom">
      <van-datetime-picker v-model="filterDate" type="date" title="选择日期" :min-date="minDate" :max-date="maxDate"  @cancel="showTimePicker = false" @confirm="getList(), showTimePicker = false"/>
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      finished: false,
      page: 1,
      activeName: 0,
      showTimePicker: false,
      filterDate: null,
      minDate: new Date(2021, 0, 1),
      maxDate: new Date(),
      userInfo: {},
      list: [],
    }
  },
  created() {
    this.getUserInfo()
    this.getList()
  },
  methods: {
    getUserInfo() {
      this.$http.get(`/bind/wxeb17149aeaf910bb/${this.$store.state.token}`).then(res => {
        if (res.code === 0) {
          this.userInfo = res.data
        } else if (res.code === 10019) {
          this.$router.replace('/bind')
        }
      })
    },
    getList(flag) {
      if (flag) {
        this.finished = false
        this.page = 1
      }
      this.loading = true
      this.$http.get(`/garbage/wxeb17149aeaf910bb/${this.$store.state.token}`, {
        params: {
          garbageType: this.activeName || '',
          page: this.page,
          limit: 20,
          startDate: this.filterDate ? this.filterDate.valueOf() : '',
          endDate: this.filterDate ? (this.filterDate.valueOf() + (1000 * 3600 * 24)) : ''
        }
      }).then(res => {
        this.loading = false
        if (res.code === 0) {
          this.list = res.data.list
          if (this.page * 20 >= res.data.total) {
            this.finished = true
          } else {
            this.page++
          }
        }
      }).catch(() => {
        this.loading = false
        this.finished = true
      })
    },
    formatTitle(item) {
      // 1易腐垃圾，2可回收物，3其他垃圾，4有害垃圾
      switch (item.garbageType) {
        case 1:
          return '易腐垃圾'
        case 2:
          return '可回收物'
        case 3:
          return '其他垃圾'
        case 4:
          return '有害垃圾'
        default:
          return '其他'
      }
    }
  }
}
</script>
<style lang="less" scoped>
.delivery-index {
  background: #F1F1F1;
  .card-info {
    justify-content: flex-start;
    height: 3.8rem;
    width: 100%;
    background-color: #1989fa;
    margin-bottom: -1.5rem;
    padding: 0 1rem 1.3rem;
    font-size: .5rem;
    color: #FFF;
    .ava {
      height: 1.5rem;
      width: 1.5rem;
      background-color: #FFF;
      margin-right: .4rem;
      border-radius: 1.5rem;
    }
  }
  .log-list {
    position: relative;
    background-color: #FFF;
    border-radius: .4rem;
    margin: .32rem;
    .search {
      position: absolute;
      bottom: 1.4rem;
      z-index: 10;
      left: 50%;
      margin-left: -30px;
      box-shadow: 0 0 10px #1989FA64;
      width: 60px;
    }
    .log-title {
      height: 1.52rem;
      border-bottom: .01rem solid #ebedf0;
    }
    .plus {
      .van-cell__value {
        color: #40AE36;
      }
    }
    .minus {
      .van-cell__value {
        color: #FF7400;
      }
    }
  }
}
</style>